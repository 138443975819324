import * as React from 'react'
import * as styles from './FramedModal.module.css'

export function isFramed() {
    return (
        typeof window !== 'undefined' && window.location !== window.top.location
    )
}

export default class extends React.Component {
    public render() {
        const breakFrame = () => {
            window.top.location = window.location
        }
        return (
            <>
                <div className={styles.overlay} />
                <div className={styles.modal}>
                    <h2>Uh oh! We've detected a frame.</h2>
                    <p>
                        It seems like you're currently viewing my website
                        through a frame. We don't like frames in the modern
                        world.
                    </p>
                    <p>
                        These can cause a number of cross domain issues, along
                        with security problems.{' '}
                        <strong>Say no to frames!</strong>
                    </p>
                    <p>
                        <a
                            href="javascript:void(0);"
                            className={styles.button}
                            onClick={breakFrame}
                        >
                            Click here to go to main website.
                        </a>
                    </p>
                </div>
            </>
        )
    }
}
