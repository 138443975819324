import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '@fontsource/merriweather/300.css'
import '@fontsource/merriweather/400.css'
import '@fontsource/lora/400.css'

import './Layout.css'
import FramedModal, { isFramed } from './FramedModal'

interface LayoutState {
    inFrame: boolean
}

export default class extends React.Component<{}, LayoutState> {
    constructor(props: any, context: any) {
        super(props, context)

        this.state = {
            inFrame: false,
        }
    }

    public componentDidMount() {
        this.setState({
            inFrame: isFramed(),
        })
    }

    public render() {
        return (
            <StaticQuery
                query={graphql`
                    query IndexQuery {
                        site {
                            siteMetadata {
                                title
                                description
                            }
                        }
                    }
                `}
                render={data => (
                    <>
                        <Helmet>
                            <title>{data.site.siteMetadata.title}</title>
                            <meta
                                name="description"
                                content={data.site.siteMetadata.description}
                            />
                            <meta
                                property="og:title"
                                content={data.site.siteMetadata.title}
                            />
                            <meta
                                property="og:description"
                                content={data.site.siteMetadata.description}
                            />
                            <meta
                                property="og:image"
                                content="http://image.thum.io/get/ogImage/https://davemacaulay.com/"
                            />
                            <meta
                                name="twitter:card"
                                content="summary_large_image"
                            />
                            <meta
                                name="twitter:creator"
                                content="@HelloMacaulay"
                            />
                            <meta
                                name="twitter:title"
                                content={data.site.siteMetadata.title}
                            />
                            <meta
                                name="twitter:description"
                                content={data.site.siteMetadata.description}
                            />
                            <meta
                                name="twitter:image"
                                content="http://image.thum.io/get/ogImage/https://davemacaulay.com/"
                            />
                        </Helmet>
                        <div
                            className={this.state.inFrame ? 'blur-content' : ''}
                        >
                            {this.props.children}
                        </div>
                        {this.state.inFrame ? <FramedModal /> : ''}
                    </>
                )}
            />
        )
    }
}
